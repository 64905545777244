import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Project} from '../../model/project';
import {Router} from '@angular/router';
import {ProjectService} from '../../core/services/project.service';
import {TeamService} from '../../core/services/team.service';
import {StatusService} from '../../core/services/status.service';

@Component({
  selector: 'app-project',
  template: `
      <div class="d-flex justify-content-end">
          <button
              class="btn btn-success mb-3 px-3"
              (click)="projects.newProject = !projects?.newProject">
              <i class="fa fa-plus"> </i>
              CREA
          </button>
      </div>
      <form #f="ngForm" class="table-responsive" (submit)="projects.saveProject(f)">
          <table class="table">
              <thead>
              <tr>
                  <th>NOME PROGETTO</th>
                  <th>CREATO DA</th>
                  <th>STATO</th>
                  <th>DATA CREAZIONE</th>
                  <th></th>
              </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let project of projects.project">
                      <td scope="row">{{project?.nome}}</td>
                      <td>{{project?.creatoDa}}</td>
                      <td>{{project?.stato}}</td>
                      <td>{{project?.dataCreazione}}</td>
                      <td>
                          <button class="btn-info btn px-3" type="button" (click)="openProject(project)">APRI</button>
                      </td>
                  </tr>
                  <!-- 
                        NUOVA ATTIVITA'
                  -->
                  <tr *ngIf="projects.newProject">
                      <td>
                          <div class="form-group">
                              <input type="text"
                                     class="form-control"
                                     [ngModel]
                                     name="title"
                                     id="title"
                                     placeholder="Titolo Progetto">
                          </div>
                      </td>
                      <td>
                          <div class="form-group">
                              <select
                                      name="creatoDa"
                                      [ngModel]
                                      id="creatoDa"
                                      class="form-control">
                                  <option
                                          *ngFor="let t of team.actualTeam">
                                      {{t?.nome}} {{t?.cognome}}
                                  </option>
                              </select>
                          </div>
                      </td>
                      <td>
                          <div class="form-group">
                              <select class="form-control"
                                      [ngModel]
                                      name="status"
                                      id="status">
                                  <option *ngFor="let state of status.actualStatus">
                                      {{state?.label}}
                                  </option>
                              </select>
                          </div>
                      </td>
                      <td>
                          <p>
                              {{today | date: 'yyyy-MM-dd'}}
                          </p>
                      </td>
                      <td>
                  </td>
              </tbody>
          </table>
          <div *ngIf="projects?.newProject">
              <button class="btn-info btn px-3" type="submit">
                  <i class="fa fa-check"></i>
                  SALVA
              </button>
              &nbsp;
              <button class="btn-danger btn px-3" (click)="projects.newProject = false">
                  <i class="fa fa-times"></i>
                  CANCELLA
              </button>
          </div>
      </form>
      <div *ngIf="!projects?.newProject" (click)="projects.addProject()"
           [ngClass]="{
                    'pointer': newProjectCursor
                }"
           (mouseenter)="newProjectCursor = true"
           (mouseleave)="newProjectCursor = false">+ Crea un nuovo progetto
      </div>

  `,
  styles: []
})
export class ProjectComponent implements OnInit {
  newProjectCursor: boolean = false;
  today: number = Date.now();

  constructor(
      private http: HttpClient,
      private projects: ProjectService,
      private router: Router,
      private team: TeamService,
      private status: StatusService,
  ) { }

  ngOnInit() {
    this.projects.listProject();
  }

  openProject(projectId: Project) {
    this.projects.openProject(projectId.id);
  }

}
