import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {URLCollaudo, PathServerCollaudo} from '../../config';
import {Auth} from '../../model/auth';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    userAuth: Auth;
    isAuth: boolean;

    constructor(
        private http: HttpClient,
        private router: Router
    ) {
    }

    login({userName, password}) {
        const params = new HttpParams()
            .set('userName', userName)
            .set('password', password);

        this.http.post<Auth>(`${URLCollaudo}${PathServerCollaudo}/autenticazione.php`, params)
            .subscribe(res => {
                console.log(res);
                if (res['error'] == true) {

                } else {
                    this.userAuth = res;
                    this.router.navigateByUrl('project');
                }
            });
    }

    logout() {
        this.http.get<Auth>(`${URLCollaudo}${PathServerCollaudo}/logout.php`)
            .subscribe(res => {
                this.userAuth = res;
                this.router.navigateByUrl('login');
            });
    }

    checkSession() {
        this.http.get<Auth>(`${URLCollaudo}${PathServerCollaudo}/controlloSessione.php`)
            .subscribe(res => this.userAuth = res);
    }

    isLogged() {
        this.http.get<Auth>(`${URLCollaudo}${PathServerCollaudo}/controlloSessione.php`)
            .subscribe(res => this.userAuth = res);
        console.log(this.userAuth);
        this.isAuth = this.userAuth ? true : false;
        console.log(this.isAuth);
        return this.isAuth;
    }
}
