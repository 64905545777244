import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {URL} from '../../config';
import {Project} from '../../model/project';
import {Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {Activity} from '../../model/activity';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  project: Project[] = [];
  varProject: Project[] = [];
  newProject: boolean = false;

  constructor(private http: HttpClient,
              private router: Router) {}

  listProject () {
    this.http.get<Project[]>(`${URL}/project`)
      .subscribe(res => this.project = res)
  }

  openProject(projectId: number) {
    this.http.get<Project>(`${URL}/project/${projectId}`)
      .subscribe(res => this.router.navigateByUrl(`project/${res.id}`))
  }

  saveProject(f: NgForm) {
    let current_datetime = new Date()
    let formatted_date = this.formatDate(current_datetime);
    const params = new HttpParams()
      .set('nome', f.value.title)
      .set('dataCreazione', formatted_date)
      .set('creatoDa', f.value.creatoDa)
      .set('stato', f.value.status);

    this.http.post<Project>(`${URL}/project`, params)
      .subscribe((result: Project) => {
        this.varProject.push(result);
        this.project = this.varProject;
        this.listProject();
        this.newProject = false;
      });
  }

  private formatDate(date: Date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  addProject() {
    this.newProject = true;
  }
}
