import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {URL} from '../../config';
import {Status} from '../../model/status';

@Injectable({
  providedIn: 'root'
})
export class StatusService {
  actualStatus: Status[];
  constructor(http: HttpClient) {
    http.get<Status[]>(`${URL}/stato`)
      .subscribe(res => this.actualStatus = res)
  }
}
