import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../core/services/auth.service';

@Component({
  selector: 'app-login',
  template: `
      <main class="app-body">
          <main class="main d-flex align-items-center">
              <div class="container">
                  <div class="row">
                      <div class="col-md-8 mx-auto">
                          <div class="card-group">
                              <div class="card p-4">
                                  <div class="card-body">
                                      <form #f="ngForm" (submit)="auth.login(f.value)">
                                          <h1>Login</h1>
                                          <p class="text-muted">
                                              Entra nel tuo account
                                          </p>
                                          <div class="input-group mb-3">
                                              <div class="input-group-prepend">
                                                  <span class="input-group-text">
                                                      <i class="fa fa-user"></i>
                                                  </span>
                                              </div>
                                              <input autocomplete="off" 
                                                     class="form-control" 
                                                     placeholder="Username" 
                                                     required="true" 
                                                     type="text"
                                                     [ngModel] name="userName">
                                          </div>
                                          <div class="input-group mb-4">
                                              <div class="input-group-prepend">
                                                  <span class="input-group-text">
                                                      <i class="fa fa-lock"></i>
                                                  </span>
                                              </div>
                                              <input autocomplete="off" 
                                                     class="form-control" 
                                                     placeholder="Password" 
                                                     required="true" 
                                                     type="password"
                                                     [ngModel] name="password">
                                          </div>
                                          <div class="row">
                                              <div class="col-6">
                                                  <button class="btn btn-primary px-4" type="submit" [disabled]="f.invalid">
                                                      <i class="fa fa-sign-in-alt"></i>Login</button>
                                              </div>
                                          </div>
                                      </form>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </main>
      </main>
  `,
  styles: []
})
export class LoginComponent implements OnInit {

  constructor(
    private auth: AuthService
  ) { }

  ngOnInit() {
  }

}
