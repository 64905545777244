import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {URLCollaudo, PathServerCollaudo} from '../../config';
import {AuthService} from './auth.service';
import {Auth} from '../../model/auth';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class AuthGuard implements CanActivate {
    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private router: Router
    ) {
    }

    canActivate() {
      const isAuth = this.auth.isLogged();
      console.log(isAuth);
      if (!isAuth) {
        this.router.navigateByUrl('login');
      }
      return isAuth;
    }
}
