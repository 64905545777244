import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './features/login/login.component';
import { ProjectComponent } from './features/project/project.component';
import { SettingComponent } from './features/setting/setting.component';
import { NavbarComponent } from './core/navbar/navbar.component';
import { CardComponent } from './shard/card/card.component';
import {HttpClientModule} from '@angular/common/http';
import { ActivityComponent } from './features/activity/activity.component';
import {FormsModule} from '@angular/forms';
import { DetailsActivityComponent } from './features/details-activity/details-activity.component';
import { LoaderComponent } from './shard/loader/loader.component';
import { SelectComponent } from './shard/select/select.component';
import { DropdownPanelComponent } from './shard/dropdown-panel/dropdown-panel.component';
import {LeftbarComponent} from './core/navbar/leftbar.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ProjectComponent,
    SettingComponent,
    NavbarComponent,
    LeftbarComponent,
    CardComponent,
    ActivityComponent,
    DetailsActivityComponent,
    LoaderComponent,
    SelectComponent,
    DropdownPanelComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
