import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {URL} from '../../config';
import {Activity} from '../../model/activity';
import {NgForm} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  activity: Activity[] = [];
  varActivity: Activity[] = [];
  type: boolean = true;
  status: boolean = true;
  newActivity: boolean = false;
  filterActive: object = [{ stato: "aperto", tipo: "tutti"}];

  constructor(
    private http: HttpClient
  ) {}

  listActivity (id: string) {
    this.http.get<Activity[]>(`${URL}/activity?idProgetto=${id}`)
      .subscribe(res => {
        this.varActivity = <Activity[]>res;
        this.defaultfilter();
      });
  }

  defaultfilter() {
    if(this.filterActive[0].stato == "tutti") {
        if(this.filterActive[0].tipo == "tutti") {
            this.activity = <Activity[]>this.varActivity.filter(value =>
              value.stato !== this.filterActive[0].stato &&
              value.tipo !== this.filterActive[0].tipo);
        }
        else {
            this.activity = <Activity[]>this.varActivity.filter(value =>
              value.stato !== this.filterActive[0].stato &&
              value.tipo === this.filterActive[0].tipo);
        }
    }
    else {
        if(this.filterActive[0].tipo == "tutti") {
            this.activity = <Activity[]>this.varActivity.filter(value =>
              value.stato === this.filterActive[0].stato &&
              value.tipo !== this.filterActive[0].tipo);
        }
        else {
            this.activity = <Activity[]>this.varActivity.filter(value =>
              value.stato === this.filterActive[0].stato &&
              value.tipo === this.filterActive[0].tipo);
        }
    }
  }

  applyFilter (f: NgForm) {
    this.filterActive[0].stato = f.value.stato;
    this.filterActive[0].tipo = f.value.tipo;

    this.defaultfilter();
  }

  defaultValue() {
    this.type = true;
    this.status = true;
  }

  changeType(
    act:Activity,
    f: NgForm
  ) {
    this.http.patch<Activity>(`${URL}/activity/${act.id}`,f.value)
      .subscribe((res: Activity) => {
          const index = this.varActivity.findIndex(d => d.id === act.id);
          this.activity[index] = res;
          this.varActivity[index] = res;
          this.defaultfilter();
          this.type = true;
        },
        error => {
          this.type = false;
        }

      );
  }

  changeStatus(
    act:Activity,
    f: NgForm
  ) {
    this.http.patch<Activity>(`${URL}/activity/${act.id}`,f.value)
      .subscribe((res: Activity) => {
          const index = this.varActivity.findIndex(d => d.id === act.id);
          this.activity[index] = res;
          this.varActivity[index] = res;
          this.defaultfilter();
          this.status = true;
        },
        error => {
          this.status = false;
        }

      );
  }

  saveActivity(f: NgForm, idProject: string) {
    let current_datetime = new Date()
    let formatted_date = this.formatDate(current_datetime);

    const params = new HttpParams()
      .set('idProgetto',`${idProject}`)
      .set('titolo', f.value.title)
      .set('descrizione', "")
      .set('creatoDa', "")
      .set('assegnatoA', f.value.assigned)
      .set('stato', f.value.status)
      .set('tipo', f.value.type)
      .set('dataCreazione', `${formatted_date}`)
      .set('dataChiusura', "");

    this.http.post<Activity>(`${URL}/activity`, params)
      .subscribe((result: Activity) => {
        this.varActivity.push(result);
        this.activity = this.varActivity;
        this.defaultfilter();
        this.newActivity = false;
      });
  }

  private formatDate(date: Date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  addActivity() {
    this.newActivity = true;
  }
}
