import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './features/login/login.component';
import {ProjectComponent} from './features/project/project.component';
import {SettingComponent} from './features/setting/setting.component';
import {ActivityComponent} from './features/activity/activity.component';
import {DetailsActivityComponent} from './features/details-activity/details-activity.component';
import {AuthGuard} from './core/services/auth.guard';

const routes: Routes = [
  { path: 'login',  component: LoginComponent },
  { path: 'project', component: ProjectComponent, canActivate: [AuthGuard] },
  { path: 'project/:idProgetto', component: ActivityComponent, canActivate: [AuthGuard] },
  { path: 'project/:idProgetto/details/:id', component: DetailsActivityComponent,canActivate: [AuthGuard]  },
  { path: 'setting', component: SettingComponent,canActivate: [AuthGuard]  },
  { path: '**', redirectTo: 'project' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
