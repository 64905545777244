import { Component, OnInit } from '@angular/core';
import {Activity} from '../../model/activity';
import {URL} from '../../config';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {FormsModule, NgForm} from '@angular/forms';
import {TeamService} from '../../core/services/team.service';
import {StatusService} from '../../core/services/status.service';
import {TypeService} from '../../core/services/type.service';

@Component({
  selector: 'app-details-activity',
  template: `
    <form #f="ngForm" (submit)="saveDetails(f)">
      <div class="row">
        <div class="col-12">
          <p *ngIf="activity">Creato da {{activity?.creatoDa}} il {{activity?.dataCreazione}}</p>
          <h6>DESCRIZIONE</h6>
          <hr>
          <div class="col-12">
            <div class="form-group row">
              <textarea
                  class="form-control col-xl-12"
                  name="descrizione"
                  [ngModel]="activity?.descrizione"
                  id="descrizione"
                  cols="30" 
                  rows="10"
                  required
              >
              </textarea>
            </div>
          </div>
          <br>
          <h6>PERSONE</h6>
          <hr>
          <div class="col-8">
            <div class="form-group row">
              <label for="assegnatoA" class="col-sm-3 col-form-label">Assegnatario</label>
              <div class="col-xl-5">
                  <select
                          name="assegnatoA"
                          [ngModel]="activity?.assegnatoA"
                          id="assegnatoA"
                          class="form-control">
                      <option
                          *ngFor="let t of team.actualTeam">
                          {{t?.nome}} {{t?.cognome}}
                      </option>
                  </select>
              </div>
            </div>
          </div>
          <br>
          <h6>DETTAGLI</h6>
          <hr>
          <div class="col-8">
            <div class="form-group row">
              <label for="assegnatoA" class="col-sm-2 col-form-label">Data</label>
              <div class="col-md-4">
                <input
                  type="date"
                  class="form-control"
                  [ngModel]="activity?.dataCreazione"
                  id="dataCreazione"
                  name="dataCreazione"
                  required
                >
              </div>
                -
              <div class="col-md-4">
                <input
                  type="date"
                  class="form-control"
                  [ngModel]="activity?.dataChiusura"
                  id="dataChiusura"
                  name="dataChiusura"
                >
              </div>
            </div>
          </div>
            <div class="col-8">
                <div class="form-group row">
                    <label for="assegnatoA" class="col-sm-3 col-form-label">Stato</label>
                    <div class="col-xl-5">
                        <select
                                name="stato"
                                [ngModel]="activity?.stato"
                                id="stato"
                                class="form-control">
                            <option
                                    *ngFor="let s of status.actualStatus">
                                {{s?.label}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>        
          <div class="mt-2">
              <button type="submit" class="btn btn-info"><i class="fa fa-check"></i></button>&nbsp;
              <button class="btn btn-danger"><i class="fa fa-times"></i></button>
          </div>
        </div>
      </div>
    </form>
  `,
  styles: []
})
export class DetailsActivityComponent {
  activity: Activity;

  constructor(
    private team: TeamService,
    private status: StatusService,
    private types: TypeService,private activatedRoute: ActivatedRoute,
    private http: HttpClient
  ) {
    const id = +this.activatedRoute.snapshot.params['id'];
    const idProgetto = +this.activatedRoute.snapshot.params['idProgetto'];

    this.http.get<Activity>(`${URL}/activity?id=${id}&idProgetto=${idProgetto}`)
      .subscribe((res: Activity) => {
        this.activity = res[0];
      })
  }

  saveDetails(form: NgForm) {
    const id = +this.activatedRoute.snapshot.params['id'];

    this.http.patch<Activity>(`${URL}/activity/${id}`,form.value)
      .subscribe((res: Activity) => {

      })
  }

}
