import { Component } from '@angular/core';
import {AuthService} from './core/services/auth.service';

@Component({
  selector: 'app-root',
  template: `
      <app-navbar></app-navbar>
      <div class="d-flex" id="wrapper">
          <app-leftbar class="container-default"></app-leftbar>
          <div id="page-content-wrapper" class="pt-3 pb-3 pl-3 pr-3">
              <router-outlet></router-outlet>
          </div>
      </div>
  `,
  styles: [`
    
  `]
})
export class AppComponent {
  title = 'ProjectManager';

  constructor(
    private auth: AuthService
  ) {

  }
}
