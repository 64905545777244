import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {URL} from '../../config';
import {Team} from '../../model/team';

@Injectable({
  providedIn: 'root'
})
export class TeamService {
  actualTeam: Team[];
  constructor(http: HttpClient) {
    http.get<Team[]>(`${URL}/team`)
      .subscribe(res => this.actualTeam = res)
  }
}
