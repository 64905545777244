import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-select',
  template: `
  `,
  styles: [``]
})
export class SelectComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
