import { Component, OnInit } from '@angular/core';
import {AuthService} from '../services/auth.service';

@Component({
  selector: 'app-leftbar',
  template: ` 
      <div class="bg-bar border-right " id="sidebar-wrapper" *ngIf="userAuth?.isAuth">
          <div class="sidebar-heading text-center text-white">
              Benvenuto <br>
              <span>
                  {{userAuth.userAuth?.nome}} {{userAuth.userAuth?.cognome}}
              </span>
          </div>
          <div class="list-group list-group-flush">
              <a routerLink="project"
                 routerLinkActive="bg-active"
                 class="list-group-item list-group-item-action bg-default border-defaut"
              >
                  <i class="fa fa-project-diagram"></i>  Progetti
              </a>
              <a routerLink="setting"
                 routerLinkActive="bg-active"
                 class="list-group-item list-group-item-action bg-default border-defaut"
              >
                  <i class="fa fa-cog"></i>  Impostazioni
              </a>
              <a routerLink="logout"
                 routerLinkActive="bg-active"
                 class="list-group-item list-group-item-action bg-default border-defaut"
              >
                  <i class="fa fa-sign-out-alt"></i>  Esci
              </a>
          </div>
      </div>
  `,
  styles: [`
    #sidebar-wrapper {
        height: 100%;
    }
  `]
})
export class LeftbarComponent implements OnInit {

  constructor(
    private userAuth: AuthService
  ) {

  }

  ngOnInit() {
  }

}
