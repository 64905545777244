import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {URL} from '../../config';
import {Type} from '../../model/type';

@Injectable({
  providedIn: 'root'
})
export class TypeService {
  actualType: Type[];
  constructor(http: HttpClient) {
    http.get<Type[]>(`${URL}/tipo`)
      .subscribe(res => this.actualType = res)
  }
}
