import { Component, OnInit } from '@angular/core';
import {AuthService} from '../services/auth.service';
import index from '@angular/cli/lib/cli';

@Component({
  selector: 'app-navbar',
  template: `    
      <nav class="navbar navbar-expand-sm navbar-light bg-nav default-nav" *ngIf="userAuth?.isAuth">
          <a class="navbar-brand" routerLink="project">LOGO HERE</a>
          <button class="navbar-toggler d-lg-none" type="button" data-toggle="collapse" data-target="#collapsibleNavId"
                  aria-controls="collapsibleNavId"
                  aria-expanded="false" aria-label="Toggle navigation">
              <span class="fa fa-bars text-white font-size-icon"></span>
          </button>
          <div class="collapse navbar-collapse" 
               id="collapsibleNavId">
              <div class="list-group list-group-flush">
                  <a routerLink="project"
                     routerLinkActive="bg-active"
                     class="list-group-item list-group-item-action bg-default border-defaut"
                  >
                      <i class="fa fa-project-diagram"></i>  Progetti
                  </a>
                  <a routerLink="setting"
                     routerLinkActive="bg-active"
                     class="list-group-item list-group-item-action bg-default border-defaut"
                  >
                      <i class="fa fa-cog"></i>  Impostazioni
                  </a>
                  <a routerLink="setting"
                     routerLinkActive="bg-active"
                     class="list-group-item list-group-item-action bg-default border-defaut"
                  >
                      <i class="fa fa-sign-out-alt"></i>  Esci
                  </a>
              </div>
          </div>
      </nav>
  `,
  styles: [`
      @media (min-width: 576px){
          .navbar-expand-sm .navbar-collapse {
              flex-basis: 100% !important;
              flex-grow: 1 !important;
              align-items: center !important;
              display: none !important;
          }
      }
    .font-size-icon {
        font-size:27px
    }
  `]
})
export class NavbarComponent {
  window: any;

  constructor(
    private userAuth: AuthService
  ) {

  }
}
