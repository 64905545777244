import {Component} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Activity} from '../../model/activity';
import {StatusService} from '../../core/services/status.service';
import {TypeService} from '../../core/services/type.service';
import {TeamService} from '../../core/services/team.service';
import {ActivityService} from '../../core/services/activity.service';

@Component({
  selector: 'app-activity',
  template: `    
      <div class="d-flex justify-content-end">
          <button
              class="btn btn-success mt-3 mb-3 mr-2 px-3"
              (click)="server.newActivity = !server?.newActivity">
              <i class="fa fa-plus"> </i>
              CREA
          </button>
          <button
              class="btn btn-outline-info mt-3 mb-3 px-3"
              (click)="filter = !filter">
              <i class="fa fa-filter"> </i>
              FILTRO
          </button>
      </div>
      <div *ngIf="filter">
          <div class="card bg-light mb-3">
              <div class="card-header">
                  APPLICA I FILTRI 
                  <span (click)="filter = false" 
                        class="pointer float-right">
                  <i class="fa fa-times"></i>
                  </span>
              </div>
              <div class="card-body">
                  <div class="form-group">
                      <form #f2="ngForm" (submit)="server.applyFilter(f2)">
                          <div class="form-row">
                              <div class="form-group col-md-6">
                                    <label for="stato">STATO</label>
                                    <select
                                            name="stato"
                                            id="stato"
                                            [ngModel]="server.filterActive[0].stato"
                                            class="form-control input-group">
                                        <option>tutti</option>
                                        <option
                                                [selected]="server.filterActive[0].stato === s?.label"
                                                *ngFor="let s of status.actualStatus">
                                            {{s?.label}}
                                        </option>
                                    </select>
                              </div>
                              <div class="form-group col-md-6">
                                  <label for="stato">TIPO</label>
                                  <select
                                          name="tipo"
                                          id="tipo"
                                          [ngModel]="server.filterActive[0].tipo"
                                          class="form-control input-group">
                                      <option>tutti</option>
                                      <option
                                              [selected]="server.filterActive[0].tipo === t?.label"
                                              *ngFor="let t of types.actualType">
                                          {{t?.label}}
                                      </option>
                                  </select>
                              </div>
                          </div>
                          <button class="btn btn-success mt-2 px-3">APPLICA</button>
                      </form>
                  </div>
              </div>
          </div>

      </div>
      <form #f="ngForm" class="table-responsive" (submit)="server.saveActivity(f, idProject)">
          <table class="table">
              <thead>
              <tr>
                  <th style="width: 10%;">ID</th>
                  <th style="width: 35%;">TITOLO</th>
                  <th style="width: 15%;">TIPO</th>
                  <th style="width: 15%;">STATO</th>
                  <th style="width: 25%;">ASSEGATARIO</th>
                  <th></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let act of server.activity">
                  <td scope="row">
                      {{act?.id}}
                  </td>
                  <td>{{act?.titolo}}</td>
                  <td>
                      <span *ngIf="server?.type" class="badge"
                            (click)="changeType(act?.id)"
                            [ngClass]="{
                            'badge-danger': act?.tipo==='bug',
                            'badge-secondary': act?.tipo==='task'}">
                          <i class="fa" [ngClass]="{
                              'fa-bug':  act?.tipo==='bug',
                              'fa-tasks':  act?.tipo==='task'
                          }"></i>
                          {{act?.tipo}}
                      </span>
                      <form [ngSwitch]="tipo"  *ngIf="!server?.type" #f1="ngForm">
                          <select *ngSwitchCase="act?.id"
                              (change)="server.changeType(act, f1)"
                              name="tipo"
                              id="tipo" 
                              class="form-control"
                              [ngModel]="act?.tipo"
                          >
                              <option
                                  [selected]="act?.tipo===t?.label"
                                  *ngFor="let t of types.actualType">
                                  {{t?.label}}
                              </option>
                          </select>
                          <span *ngSwitchDefault class="badge"
                                (click)="changeType(act?.id)"
                                [ngClass]="{
                            'badge-danger': act?.tipo==='bug',
                            'badge-secondary': act?.tipo==='task'}">
                          <i class="fa" [ngClass]="{
                              'fa-bug':  act?.tipo==='bug',
                              'fa-tasks':  act?.tipo==='task'
                          }"></i>
                              {{act?.tipo}}
                      </span>
                      </form>
                  </td>
                  <td>
                      <span *ngIf="server?.status" class="badge"
                          (click)="changeStatus(act?.id)"
                          [ngClass]="{
                              'badge-success': act?.stato==='aperto',
                              'badge-warning': act?.stato==='pending',
                              'badge-danger': act?.stato==='chiuso'
                          }">
                          <i class="fa" [ngClass]="{
                              'fa-lock-open': act?.stato==='aperto',
                              'fa-clock': act?.stato==='pending',
                              'fa-lock': act?.stato==='chiuso'
                          }"></i>
                          {{act?.stato}}
                      </span>
                      <form [ngSwitch]="stato" *ngIf="!server?.status" #f1="ngForm">
                          <select *ngSwitchCase="act?.id"
                                  (change)="server.changeStatus(act, f1)"
                                  name="stato"
                                  id="stato"
                                  class="form-control"
                                  [ngModel]="act?.stato"
                          >
                              <option
                                      [selected]="act?.tipo===s?.label"
                                      *ngFor="let s of status.actualStatus">
                                  {{s?.label}}
                              </option>
                          </select>
                          <span *ngSwitchDefault class="badge"
                                (click)="changeStatus(act?.id)"
                                [ngClass]="{
                              'badge-success': act?.stato==='aperto',
                              'badge-warning': act?.stato==='pending',
                              'badge-danger': act?.stato==='chiuso'
                          }">
                          <i class="fa" [ngClass]="{
                              'fa-lock-open': act?.stato==='aperto',
                              'fa-clock': act?.stato==='pending',
                              'fa-lock': act?.stato==='chiuso'
                          }"></i>
                              {{act?.stato}}
                      </span>
                      </form>
                  </td>
                  <td>
                      {{act?.assegnatoA}}
                  </td>
                  <td>
                      <button type="button" class="btn-info btn px-3" (click)="openActivity(act)">DETTAGLI</button>
                  </td>
              </tr>
              <!-- 
                    NUOVA ATTIVITA'
              -->
              <tr *ngIf="server.newActivity">
                  <td></td>
                  <td>
                      <div class="form-group">
                          <input type="text"
                                 class="form-control"
                                 [ngModel]
                                 name="title"
                                 id="title"
                                 placeholder="Titolo Attività">
                      </div>
                  </td>
                  <td>
                      <div class="form-group">
                          <select class="form-control"
                                  [ngModel]
                                  name="type"
                                  id="type">
                              <option *ngFor="let type of types.actualType">
                                  {{type?.label}}
                              </option>
                          </select>
                      </div>
                  </td>
                  <td>
                      <div class="form-group">
                          <select class="form-control"
                                  [ngModel]
                                  name="status"
                                  id="status">
                              <option *ngFor="let state of status.actualStatus">
                                  {{state?.label}}
                              </option>
                          </select>
                      </div>
                  </td>
                  <td>
                      <div class="form-group">
                          <select
                                  name="assigned"
                                  [ngModel]
                                  id="assigned"
                                  class="form-control">
                              <option
                                      *ngFor="let t of team.actualTeam">
                                  {{t?.nome}} {{t?.cognome}}
                              </option>
                          </select>
                      </div>
                  </td>
                  <td>
                  </td>
              </tr>
              </tbody>
          </table>
          <div *ngIf="server?.newActivity">
              <button class="btn-info btn px-3" type="submit">
                  <i class="fa fa-check"></i>
                  SALVA
              </button>
              &nbsp;
              <button class="btn-danger btn px-3" (click)="server.newActivity = false">
                  <i class="fa fa-times"></i>
                  CANCELLA
              </button>
          </div>
      </form>
      <div *ngIf="!server?.newActivity" (click)="server.addActivity()"
           [ngClass]="{
                    'pointer': newProjectCursor
                }"
           (mouseenter)="newProjectCursor = true"
           (mouseleave)="newProjectCursor = false">+ Crea una nuova attività
      </div>
      <i class="float-sm-right">{{server.activity?.length}} results</i>
      <button class="btn btn-danger mt-2 px-3" *ngIf="!server.type || !server.status" (click)="server.defaultValue()">ANNULLA</button>
  `,
  styles: [`
      .bg-red {
          color: red;
      }
      .bg-gray {
          color: gray;
      }
      .pointer {
          cursor: pointer;
      }
  `]
})
export class ActivityComponent {
  newProjectCursor: boolean = false;
  idProject: any;
  filter: boolean = false;
  stato: number;
  tipo: number;

  constructor(
    private team: TeamService,
    private status: StatusService,
    private server: ActivityService,
    private types: TypeService,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.idProject = +this.activatedRoute.snapshot.params['idProgetto'];
    this.server.listActivity(this.idProject);
  }

  openActivity(
    att: Activity
  ) {
    this.router.navigateByUrl(`project/${att.idProgetto}/details/${att.id}`);
    this.server.newActivity = false;
  }

  changeStatus(id: number) {
    this.server.status = !this.server.status;
    this.stato = id;
  }
  changeType(id: number) {
    this.server.type = !this.server.type;
    this.tipo = id;
  }
}
